import React from 'react'

import './../../Components/CSS/Home.css';
import Accordion from 'react-bootstrap/Accordion';
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();


const Home = () => {
    return (

        <div>
            <div className="App">
                <div>

                    <div className='yes'>
                        <div className="hero-text">
                            <h1 className="blue">Albion Radar</h1>
                            <p>The best albion radar on the market in terms of price and capabilities </p>
                        </div>
                    </div>

                    <div data-aos="fade-up" data-aos-delay="100">
                        <div className='qqq'><a href="https://albion-radar.com/registration">
                            <div className='buttonguton'>
                                <p className='center'>Register</p>
                            </div>
                        </a>
                            <a href="https://albion-radar.com/login">
                                <div className='buttonguton'>
                                    <p className='center'>Login</p>
                                </div>
                            </a>
                        </div>
                    </div>

                    <div className='pis App' data-aos="fade-up" data-aos-delay="100">
                        <div className='element2'><h2 className='lightblue'>#Albion Radar</h2>
                            <h3 className='text'>Albion Radar Benefits</h3>
                            <p className='p'>Implement high-performing Albion Radar solutions that will allow you to
                                improve various aspects of the game and gain an advantage over other players. Albion
                                Radar is an advanced data analysis tool that provides comprehensive monitoring
                                solutions.
                            </p>
                            <div className='features img'>Albion Online Radar Shows: Players including (Nick, Health, Mount, New Player Sound,
                                Items, Distance to Players).
                            </div>
                            <div className='features img'>Albion Online Radar Shows: Dungeon, Mist contain their spell, Corrupted, Hellgate(2v2,
                                5v5, 10v10).
                            </div>
                            <div className='features img'>Albion Online Radar Shows: Normal mobs with hp (if more hp than more exp), enchanted
                                mobs.
                            </div>
                            <div className='features img'>Albion Online Radar Shows: Any resources and resource mobs with enchantment level and
                                 number of resources.
                            </div>
                            <div className='features img'>Albion Online Radar Shows: Show chests and their quality.</div>
                            <div className='features img'>Albion Online Radar Shows: Radar display settings, menu in the application.</div>
                            <div className='features img'>Albion Online Radar Shows: Misty bosses, Cristal Spiders.</div>
                            <div className='features img'>Albion Online Radar Shows: Type of mobs, Harvestable Mobs.</div>
                            <div className='features img'>Albion Online Radar Shows: Ignore list (players/ally/guild).</div>
                        </div>
                        <div>
                            <div className='logov3' data-aos="fade-up" data-aos-delay="100"></div>
                        </div>
                    </div>


                    <div className='Po Pap' data-aos="fade-up" data-aos-delay="100">
                        <div>
                            <a href="https://www.youtube.com/@uszczelkapodglowica/videos">
                                <div className='noclass'>
                                </div>
                            </a>
                        </div>
                        <div className='build'>
                            <div className='element2'><h2 className='lightblue'>#Albion Radar</h2></div>
                            <h3 className='text'>Albion Radar Videos</h3>
                            <p className='p'> You can also watch the albion radar gameplay video on our youtube chanel.
                                On our recordings you can also see how the functions described above work
                            </p>
                        </div>
                    </div>


                    <div className='pis App' data-aos="fade-up" data-aos-delay="100">
                        <div className='element2'><h2 className='lightblue'>#Albion Radar</h2>
                            <h3 className='text'>Albion Radar West/East/Europe</h3>
                            <p className='p'>Join our Discord community and gain access to the powerful Albion Online Radar! Designed for the West, East, Europe servers, Albion Radar has remained undetected for over 12 months, ensuring a seamless experience. Fully compatible with Windows 7, 8, 10, and 11, Albion Radar provides you with the ultimate edge in Albion Online.
                            </p>
                        </div>
                        <div>

                            <a href="https://discord.gg/PD3sWq3vap" target="_blank" rel="noopener noreferrer">
                                <div className='logov4' data-aos="fade-up" data-aos-delay="100"></div>
                            </a>
                        </div>
                    </div>


                    <div className='Po Pap' data-aos="fade-up" data-aos-delay="100">
                        <div>
                                <div className='noclass2'>
                                </div>
                        </div>
                        <div className='build'>
                            <div className='element2'><h2 className='lightblue'>#Albion Radar</h2></div>
                            <h3 className='text'>Albion Radar </h3>
                            <p className='p'> Our program is easy to use and offers a pleasant and intuitive application
                                made in C# with the user in mind. If there are any inconveniences that bother the user,
                                we can also make an individual version with a program code different from the rest,
                                which will allow it to remain undetected
                            </p>
                        </div>
                    </div>

                </div>
            </div>
            <div className='Container tech '>
                <div data-aos="fade-up" data-aos-delay="200">
                    <div>
                        <h3 className='collumns'>Albion Radar in game </h3>
                    </div>
                    <div>
                        <p className='margin center'>Some photos of GUI and radar look in game </p>
                    </div>
                </div>
            </div>
            <div className='space'></div>
            <div data-aos="fade-up" data-aos-delay="600" className='radarphoto'></div>
            <div data-aos="fade-up" data-aos-delay="800" className='radarphoto2'></div>

            <div className='Container tech colour'>
                <div data-aos="fade-up" data-aos-delay="200">
                    <div>
                        <h3 className='collumns'>What Technologies we use to programming Albion Radar</h3>
                    </div>
                    <div>
                        <p className='margin center'>We use the best technologes to programming Albion Radar there is
                            some examples </p>
                    </div>
                </div>
                <ul className='list'>
                    <li data-aos="fade-up" data-aos-delay="200">
                        <a href="/" class="clip-each angular" data-aos="fade-up" data-aos-delay="200">
                        </a>
                        <a href="/">
                            <h5 className='center2'>C#</h5>
                        </a>
                        <p className='discription'>C# (C Sharp) is a modern, high-level, general-purpose programming
                            language developed by Microsoft. It was created as part of the Microsoft .NET framework and
                            is designed to be a versatile language for building a wide range of applications.</p>

                    </li>
                    <li data-aos="fade-up" data-aos-delay="400">
                        <a href="/" class="clip-each java">
                        </a>
                        <a href="/">
                            <h5 className='center2'>C++</h5>
                        </a>
                        <p className='discription'>C++ is a powerful, general-purpose programming language that was
                            developed as an extension of the C programming language. It was created with a focus on
                            efficiency, performance, and low-level system programming.</p>
                    </li>
                    <li data-aos="fade-up" data-aos-delay="600">
                        <a href="/" class="clip-each  react ">
                        </a>
                        <a href="/">
                            <h5 className='center2'>React</h5>
                        </a>
                        <p className='discription'>Thanks to React.js (JavaScript framework), our front-end developers
                            build beautiful and modern user interfaces operating as progressive web applications</p>
                    </li>
                </ul>
            </div>

            <div className='contactus'>
                <div className='contactus-info ' data-aos="fade-up" data-aos-delay="200">
                    <div>
                        <h3>Are you intrested in Albion Radar Product? </h3>
                    </div>
                    <div>
                        <p className='short-title'>Join our community on discord and ask one of our admins: <br></br>
                        </p>
                        fucksbi
                        <br></br>
                    </div>

                </div>
                <div className='buttoncontact' data-aos="fade-up" data-aos-delay="200">
                    <a href="https://discord.gg/8UkYp7M2aD" target="_blank" rel="noopener noreferrer">
                        <button class="btn gradient ">Contact Us</button>
                    </a>
                </div>
            </div>


            <div className='selection-faq container' data-aos="fade-up" data-aos-delay="100">
                <div className='containerdofaq'>
                    <div className='margin'>
                        <h2 className='lightblue'>FAQ</h2>
                        <h3>Need help with anything?</h3>
                    </div>
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="1" data-aos="fade-up" data-aos-delay="150">
                            <Accordion.Header>Is it safe to use Albion Radar?</Accordion.Header>
                            <Accordion.Body>
                                He's been on the market for 6 months and I've never been detected before and he's
                                avoided ban waves. You use at your own risk.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2" data-aos="fade-up" data-aos-delay="200">
                            <Accordion.Header>Does it matter if I turn on Albion Radar before starting or after turning
                                it on?
                            </Accordion.Header>
                            <Accordion.Body>
                                It doesn't matter radar works any way, also you don't need to run it as administrator.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3" data-aos="fade-up" data-aos-delay="250">
                            <Accordion.Header>Can see users who used Invisibility Potion and Invisibility water well?
                            </Accordion.Header>
                            <Accordion.Body>
                                No but in frist example you will see him faster than he can use this invis. It is the
                                same when the cape is activated, the player will not be able to leave the radar area
                                unnoticed.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4" data-aos="fade-up" data-aos-delay="300">
                            <Accordion.Header>Is there a way to increase player detection distance?</Accordion.Header>
                            <Accordion.Body>
                                Unfortunately, we do not have such a possibility, our radar works only on the data that
                                the server sends us.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5" data-aos="fade-up" data-aos-delay="350">
                            <Accordion.Header>Does the radar work on the east/west server?</Accordion.Header>
                            <Accordion.Body>
                                Yes, Albion Radar works on both servers
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="6" data-aos="fade-up" data-aos-delay="400">
                            <Accordion.Header>How to protect yourself from a ban?</Accordion.Header>
                            <Accordion.Body>
                                You must follow certain rules such as not sharing your screen with third parties, not
                                telling anyone that you are using Albion Radar, not giving in-game information to people
                                you do not trust. Not writing in the game about using the radar, and not provoking
                                behavior that jeopardizes your account.
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                </div>
            </div>
            <div style={{
                padding: '20px',
                fontFamily: 'Arial, sans-serif',
                margin: 'auto',
                marginTop: '50px',
                maxWidth: '1200px'
            }}>
                <h1>Albion Online Radar and Helper Tools Overview</h1>
                <p>
                    In the expansive and competitive world of Albion Online, players are
                    always looking for an edge to outmaneuver their foes and optimize their
                    gameplay. Tools such as <strong>Albion Online Radar</strong>,{' '}
                    <strong>Albion Radar</strong>, and other Albion Online cheats or
                    helpers can provide valuable information for players navigating this
                    sandbox MMO. Whether you're seeking efficient resource gathering,
                    combat strategy, or survival tips, leveraging these tools can
                    revolutionize your experience.
                </p>

                <h2>Key Features of Albion Online Radar</h2>
                <ol>
                    <li>
                        <h3>Player Detection</h3>
                        <p>
                            Albion Radar tracks and displays detailed information about nearby
                            players:
                        </p>
                        <ul>
                            <li>Nicknames: Quickly identify friends, foes, or neutrals.</li>
                            <li>Health: Monitor the health levels of nearby players.</li>
                            <li>Mount Information: See what mounts others are using.</li>
                            <li>
                                New Player Sound Alerts: Get sound notifications for new players
                                entering your vicinity.
                            </li>
                            <li>Carried Items: Discover the items players are carrying.</li>
                            <li>Distance Metrics: Know how far other players are from you.</li>
                        </ul>
                    </li>

                    <li>
                        <h3>Dungeon and Mist Exploration</h3>
                        <p>
                            Albion Online Radar simplifies dungeon and Mist navigation:
                        </p>
                        <ul>
                            <li>
                                Spell Information: Identify spells in Corrupted Dungeons and
                                Hellgates.
                            </li>
                            <li>Dungeon Maps: Get a clear layout of dungeons.</li>
                            <li>
                                Player Detection: Spot hidden players before encountering them.
                            </li>
                        </ul>
                    </li>

                    <li>
                        <h3>Mobs and Monsters</h3>
                        <p>
                            For combat-focused players, the radar provides data about mobs:
                        </p>
                        <ul>
                            <li>Normal Mobs: Displays health and experience values.</li>
                            <li>Enchanted Mobs: Highlights enchanted mobs for better loot.</li>
                            <li>
                                Resource and World Bosses: Pinpoint high-value combat
                                opportunities.
                            </li>
                            <li>
                                Crystal Spiders and Mist Bosses: Detect rare encounters.
                            </li>
                        </ul>
                    </li>

                    <li>
                        <h3>Resource Tracking</h3>
                        <p>Optimize resource collection with these features:</p>
                        <ul>
                            <li>Resource Nodes: Detect resources and enchantment levels.</li>
                            <li>
                                Resource Mobs: Locate harvestable mobs and their yield potential.
                            </li>
                            <li>
                                Chests: Spot treasure chests and their quality in various zones.
                            </li>
                        </ul>
                    </li>

                    <li>
                        <h3>Radar Display and Overlay</h3>
                        <p>Customize the radar to suit your preferences:</p>
                        <ul>
                            <li>Display Settings: Adjust information visibility.</li>
                            <li>Game Overlay: Seamlessly integrate the radar into the game.</li>
                        </ul>
                    </li>

                    <li>
                        <h3>Skill and Cooldown Management</h3>
                        <p>Monitor your skills and cooldowns in real-time for combat readiness.</p>
                    </li>

                    <li>
                        <h3>Optimization and Performance</h3>
                        <p>Ensure smooth gameplay with these features:</p>
                        <ul>
                            <li>Lag-Free Optimization: Minimal performance impact.</li>
                            <li>
                                Customization: Prioritize critical data, reducing screen clutter.
                            </li>
                        </ul>
                    </li>

                    <li>
                        <h3>Ignore List</h3>
                        <p>Manage your radar notifications:</p>
                        <ul>
                            <li>Exclude specific players, allies, or guild members.</li>
                        </ul>
                    </li>
                </ol>

                <h2>Future Features in Albion Online Radar</h2>
                <ul>
                    <li>Advanced detection for hidden players, mobs, and items.</li>
                    <li>Expanded rare resource data, including spawn timers.</li>
                    <li>Improved dungeon mapping and boss strategies.</li>
                    <li>
                        Cross-Guild Communication Tools: Share radar data in real-time.
                    </li>
                    <li>
                        Mobile Integration: A companion app for tracking on the go.
                    </li>
                </ul>

                <h2>Why Use Albion Online Radar?</h2>
                <p>
                    Tools like Albion Online Radar provide numerous advantages:
                </p>
                <ul>
                    <li>Survival: Detecting nearby players in PvP zones protects your loot.</li>
                    <li>Efficiency: Locate high-value resources and mobs quickly.</li>
                    <li>Strategic Edge: Outmaneuver opponents with superior data.</li>
                    <li>
                        Optimized Gameplay: Spend less time wandering, more time achieving
                        goals.
                    </li>
                </ul>

                <h2>Conclusion</h2>
                <p>
                    Albion Online Radar is a transformative tool for players seeking to elevate their experience. With features like Albion Radar player tracking, Albion Radar PvP detection, and Albion Radar resource scanning, this tool provides unparalleled insights into Albion Radar dungeons, Albion Radar combat strategies, and Albion Radar resource nodes. Whether you're using Albion Radar to dominate in Albion Hellgates, uncover hidden treasures in Albion Radar Mist zones, or optimize your farming with Albion Radar resource tracking, this tool ensures you're always a step ahead.

                    As updates roll out, Albion Radar enhancements will include advanced Albion Radar mob detection, improved Albion Radar dungeon mapping, and real-time Albion Radar rare resource alerts. Features like Albion Radar player proximity tracking, Albion Radar skill monitoring, and Albion Radar overlay customization make it an indispensable tool for any player. With Albion Radar, you can locate Albion Radar enchanted mobs, pinpoint Albion Radar world bosses, and gain a competitive edge in Albion Radar PvP zones.

                    Using Albion Radar, players can track Albion Radar Crystal Spiders, monitor Albion Radar skill cooldowns, and detect Albion Radar treasure chests with ease. Leverage Albion Radar's powerful features to outmaneuver rivals, gather resources efficiently, and dominate the ever-competitive world of Albion Online.
                </p>
            </div>
        </div>
    )
}
export default Home;
